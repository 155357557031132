<template>
    <div class="card full-height bg-ligth">
      <div class="card-header">
        <h3 class="mb-0">{{ lista_campos != "" ? "Editar" : "Criar" }} Conta</h3>
      </div>
      <div class="card-body">
        <div class="col-md-12">
          <div class="form">
            <div class="row form-group">
              <h4 class="col-md-12">Aliquota:</h4>
          
  
              <div class="row form-group col-md-12">
                <div class="col-md-3">
                  <label for="porcentagem">Porcetagem:</label>
                  <input
                    required=""
                    type="text"
                    class="form-control"
                    v-mask="variableWithMask2"
                    v-model="porcentagem"
                    placeholder="Digite a Aliquota"
                  />
                </div>
              
                <div class="col-md-3">
                  <label for="dias_uteis">Dias Uteis:</label>
                  <input
                    
                    type="test"
                    class="form-control"
                    v-model="form.dias_uteis"
                    placeholder="Digite o quantidade de dias uteis"
                  />
                </div>
                  
                <div class="col-md-3">
                  <label for="saldo">Data incial:</label>
                  <input
                    required=""
                    type="date"
                    class="form-control"
                    v-model="form.data_inicial"
                  />
                </div>
                <div class="col-md-3">
                  <label for="saldo">Data final:</label>
                  <input
                    required=""
                    type="date"
                    class="form-control"
                    v-model="form.data_final"
                  />
                </div>
            </div>
          </div>
        </div>
  
        <div class="row justify-content-end">
          <div class="col-md-12 text-center">
            <v-btn
              color="primary"
              elevation="2"
              :loading="verif"
              @click.prevent="confirm(lista_campos == '' ? 'cria' : 'edita')"
              class="btn btn-primary"
              :disabled="verif"
              >{{ lista_campos == "" ? "cria" : "edita" }}</v-btn
            >
          </div>
        </div>
  
        <!-- </div> -->
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import { searchAddressMixin } from "@/core/mixins/searchAddressMixin";
  import { fireAlert } from "@/core/mixins/alertMessageMixin";
  
  export default {
    mixins: [searchAddressMixin, fireAlert],
    data() {
      return {
        verif: false,
  
        form: {
          porcentagem: null,
          dias_uteis: null,
          data_inicial: null,
          data_final: null,
        },
        variableWithMask: "",
        variableWithMask2: "",
          porcentagem: null,
  
        paramestado: "",
        value: "",
        loading: false,
        cpf_cnpj1: null,
        saldo: null,
      };
    },
  
    computed: {
      lista_usuarios() {
        let usuarios = Object.values(
          this.$store.state.configUsuarios.lista_usuarios
        );
  
        let data = [];
        for (let i = 0; i < usuarios.length; i++) {
          data.push({ id: usuarios[i].id, label: usuarios[i].nome_sobrenome });
        }
        return data;
      },
      mensagem_alert() {
        return this.$store.state.aliquotas.mensagem_alert;
      },
      lista_perfil() {
        return this.$store.state.configEmpresa.lista_perfil;
      },
      lista_campos() {
        return this.$store.state.configEmpresa.lista_campos;
      },
    },
    created() {
      this.preenxerCampor();
    },
    watch: {
       
      porcentagem: function () {
      
        this.variableWithMask2   = "%#,##";
        console.log(this.variableWithMask2);
        this.form.porcentagem = parseFloat(
          this.porcentagem.replace("%", "").replace(",", ".")
        );
      },
    },
    methods: {
      async confirm(tipo) {
        await this.$confirm({
          title: "Tem Certeza?",
          message: `Esta ação  ` + tipo + ` uma conta para o cliente no sistema?`,
          button: {
            no: "No",
            yes: "Si",
          },
          callback: (confirm) => {
            if (confirm) {
              if (tipo == "cria") this.create();
              if (tipo == "edita") this.update();
            }
          },
        });
      },
      async create() {
        this.verif = true;
        await this.$store.dispatch("aliquotas/create_aliquota", this.form);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert,
          routeName: "aliquota",
        });
      },
      onInput(value) {
           let usuarios = Object.values(
          this.$store.state.configUsuarios.lista_usuarios
        );
          usuarios = usuarios.filter(
              (use) => use.id === value
          );
          console.log(usuarios[0])
          this.cpf_cnpj1 = usuarios[0].cpf
      },
      async update() {
        this.verif = true;
        await this.$store.dispatch("aliquotas/update_aliquota", this.form);
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert,
          routeName: "aliquota",
        });
      },
      async preenxerCampor() {
        await this.$store.dispatch("configUsuarios/listar_usuarios_sistema");
  
        if (this.lista_campos != "")
          this.form = {
            id: this.lista_campos.id,
            tipo: this.lista_campos.tipo,
            usuario_id: this.lista_campos.usuario_id,
  
            status: 1,
          };
        this.saldo = "R$" + this.lista_campos.saldo.toString().replace(".", ",");
        console.log(this.saldo);
        this.cpf_cnpj1 = this.lista_campos.cpf_cnpj;
      },
    },
  };
  </script>
  
  <style scoped>
  .lista:hover {
    background-color: rgba(167, 183, 255, 0.705);
  }
  </style>